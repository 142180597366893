<template>
  <v-card>
    <v-card-subtitle class="hb-body" > The Script section allows managers to create and manage scripts for conversations with potential customers in the Tenant Onboarding process. Follow this script, during the move in process, to gather information about the customer.
 <br>
 <br>
  To remove scripts from the Tenant Onboarding process, simply delete them from this section. Update or modify scripts on a corporate or property level, as needed,  to keep your team equipped with the best tools for engaging potential customers.</v-card-subtitle>

    <v-row class="px-6">
      <rich-text-editor
        class="editor-style"
        solo
        dense
        flat
        hide-details
        autofocus
        background-color="#FFFFFF"
        v-model="content"
        placeholder="create a script..."
        :hasBorder="true"
      />
    </v-row>
    <v-card-actions>
      <v-row>
        <v-col align="end" class="mr-4">
          <hb-btn
            color="primary"
            :disabled="disableBtn"
            class="mr-4"
            @click="save()"
            >Save</hb-btn
          >
          <hb-btn color="secondary" @click="cancel()">Clear</hb-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import RichTextEditor from "../../assets/RichTextEditor.vue";
import api from "../../../assets/api";
import { notificationMixin } from "../../../mixins/notificationMixin";

export default {
  name: "LeadScripts",
  mixins: [notificationMixin],
  data() {
    return {
      content: "",
      setupModal: "",
      property_id: "",
      disableBtn: true,
    };
  },
  components: {
    "rich-text-editor": RichTextEditor,
  },
  props: ["property_id", "disableBtn"],
  watch: {
    property_id() {
      this.fetchData();
    },
  },
  mounted() {},
  async created() {
    this.fetchData();
  },
  beforeDestroy() {},
  methods: {
    async save() {
      // console.log("saving ....", this.content);
      if (this.content == "<p></p>") {
        this.content = undefined;
      }
      const body = {
        property_id: this.property_id,
        script: this.content,
      };
      try {
        if(this.property_id){
          await this.postReq(
          api.LEADS + "properties/" + this.property_id + "/lead-script/",
          body
          );
        }else{
          await this.postReq(
          api.LEADS + "lead-script/",
          {script: this.content}
          );
        }
        this.showMessageNotification({
          type: "success",
          description: "Script Added Successfully",
        });
      } catch (error) {
        this.showMessageNotification({
          type: "error",
          description: "Something went wrong",
        });
        console.log(error);
      }
    },
    cancel() {
      // this.showMessageNotification({ type: 'success', description: 'Script Added Successfully' })
      this.content = undefined;
      console.log("cancel");
    },
    async getReq(path) {
      return api.get(this, path);
    },
    postReq(path, data) {
      return api.post(this, path, data);
    },
    async fetchData(refresh) {
      let getRES;
      if (this.property_id) {
        getRES = await this.getReq(
          api.LEADS + "properties/" + this.property_id + "/lead-script/"
        );

        this.content = getRES;
      } else {
        getRES = await this.getReq(
          api.LEADS + "lead-script/"
        );
        this.content = getRES;
      }
    },
  },
};
</script>

<style scoped>
.editor-style {
  height: 300px;
}

.editor-style >>> .tiptap {
  height: 250px;
}
</style>
